<template>
  <v-scale-transition>
    <BaseForm
      apiActions="user/user.php"
      backRoute="/userlist"
      newRecordTitle="Novi korisnik"
      listRefreshAction="user_list_needs_refresh"
      :formData="[
        {
          title: 'OSNOVNI PODACI',
          rows: [
            {
              id: 1,
              fields: [
                {
                  label: 'Korisničko ime',
                  type: 'text',
                  name: 'user_name'
                },
                {
                  label: 'Titula',
                  type: 'text',
                  name: 'title'
                },
                {
                  label: 'Ime',
                  type: 'text',
                  name: 'first_name'
                },
                {
                  label: 'Prezime',
                  type: 'text',
                  name: 'last_name'
                }
              ]
            },
            {
              id: 2,
              fields: [
                {
                  label: 'Sufiks',
                  type: 'text',
                  name: 'suffix'
                },
                {
                  label: 'Zvanje',
                  type: 'text',
                  name: 'vocation'
                },
                {
                  label: 'Spol',
                  type: 'sex',
                  name: 'sex'
                },
                {
                  label: 'UUID',
                  type: 'text',
                  name: 'uuid',
                  disabled: true
                }
              ]
            },
            {
              id: 3,
              fields: [
                {
                  label: 'Napomena',
                  type: 'text',
                  name: 'note'
                }
              ]
            }
          ]
        },
        {
          title: 'KONTAKT',
          rows: [
            {
              id: 4,
              fields: [
                {
                  label: 'Adresa',
                  type: 'text',
                  name: 'address'
                },
                {
                  label: 'Grad',
                  type: 'text',
                  name: 'city'
                },
                {
                  label: 'Poštanski broj',
                  type: 'text',
                  name: 'postal_code'
                },
                {
                  label: 'Država',
                  type: 'country',
                  name: 'country'
                }
              ]
            },
            {
              id: 5,
              fields: [
                {
                  label: 'E-mail',
                  type: 'email',
                  name: 'email'
                },
                {
                  label: 'Telefon',
                  type: 'phone',
                  name: 'phone'
                },
                {
                  label: 'Mobitel',
                  type: 'phone',
                  name: 'mobile'
                },
                {
                  label: 'Fax',
                  type: 'phone',
                  name: 'fax'
                },
                {
                  label: 'Prima obavijesti?',
                  type: 'toggle',
                  name: 'notifications'
                },
              ]
            },
            {
              id: 6,
              fields: [
                {
                  label: 'E-mail 1',
                  type: 'email',
                  name: 'email1'
                },
                {
                  label: 'E-Mail 2',
                  type: 'email',
                  name: 'email2'
                },
                {
                  label: 'E-Mail 3',
                  type: 'email',
                  name: 'email3'
                },
                {
                  label: 'Fax 1',
                  type: 'phone',
                  name: 'fax1'
                }
              ]
            },
            {
              id: 7,
              fields: [
                {
                  label: 'Telefon 1',
                  type: 'phone',
                  name: 'phone1'
                },
                {
                  label: 'Telefon 2',
                  type: 'phone',
                  name: 'phone2'
                },
                {
                  label: 'Telefon 3',
                  type: 'phone',
                  name: 'phone3'
                },
                {
                  label: 'Mobitel 1',
                  type: 'phone',
                  name: 'mobile1'
                }
              ]
            }
          ]
        },
        {
          title: 'OSTALI PODACI',
          rows: [
            {
              id: 8,
              fields: [
                {
                  label: 'Datum rođenja',
                  type: 'date',
                  name: 'birthday'
                },
                {
                  label: 'Mjesto rođenja',
                  type: 'text',
                  name: 'birthplace'
                },
                {
                  label: 'OIB',
                  type: 'oib',
                  name: 'oib'
                },
                {
                  label: 'Osobni status',
                  type: 'personalstatus',
                  name: 'personal_status'
                },
                {
                  label: 'MBZ',
                  type: 'text',
                  name: 'mbz'
                }
              ]
            },
            {
              id: 9,
              fields: [
                {
                  label: 'Kreiran',
                  type: 'text',
                  name: 'created',
                  disabled: true
                },
                {
                  label: 'Ažuriran',
                  type: 'text',
                  name: 'last_update',
                  disabled: true
                },
                {
                  label: 'Posljednja prijava',
                  type: 'text',
                  name: 'last_login',
                  disabled: true
                },
                {
                  label: 'Brisan',
                  type: 'text',
                  name: 'deleted_date',
                  disabled: true
                }
              ]
            }
          ]
        },
        {
          title: 'PODUZEĆE',
          rows: [
            {
              id: 15,
              fields: [
                {
                  label: 'Organizacijske jedinice kojima pripada osoba',
                  type: 'userroleunit',
                  name: 'user_role_unit'
                }
              ]
            }
          ]
        },
        {
          title: 'ČLANARINA',
          rows: [
            {
              id: 20,
              fields: [
                {
                  label: 'I',
                  type: 'membershipinfo'
                }
              ]
            },
            {
              id: 21,
              fields: [
                {
                  label: 'MI',
                  type: 'membershipinfo'
                }
              ]
            },
            {
              id: 10,
              fields: [
                {
                  label: 'Plaća HRO CIGRE članarinu?',
                  type: 'toggle',
                  name: 'membership_pays'
                },
                {
                  label: 'Vrsta HRO CIGRE članarine',
                  type: 'membershipname',
                  name: 'membership_name'
                },
                {
                  label: 'Popust na HRO CIGRE članarinu (%)',
                  type: 'text',
                  name: 'membership_discount'
                },
                {
                  label: 'Članski broj za HRO CIGRE članarinu',
                  type: 'text',
                  name: 'membership_regnum'
                }
              ]
            },
            {
              id: 11,
              fields: [
                {
                  label: 'HRO CIGRE članarinu plaća privatno?',
                  type: 'toggle',
                  name: 'membership_private'
                },
                {
                  label: 'HRO CIGRE pristupnica?',
                  type: 'toggle',
                  name: 'accession'
                },
                {
                  label: 'Materijali na privatnu adresu?',
                  type: 'toggle',
                  name: 'materials_to_private_address'
                },
                {
                  label: 'Datum kraja članstva',
                  type: 'date',
                  name: 'membership_end'
                }
              ]
            },
            {
              id: 12,
              fields: [
                {
                  label: 'Plaća međunarodnu članarinu?',
                  type: 'toggle',
                  name: 'i_membership_pays'
                },
                {
                  label: 'Vrsta međunarodne članarine',
                  type: 'membershipname',
                  name: 'i_membership_name'
                },
                {
                  label: 'Popust na međunarodnu članarinu (%)',
                  type: 'text',
                  name: 'i_membership_discount'
                }
              ]
            },
            {
              id: 13,
              fields: [
                {
                  label: 'Članski broj za međunarodnu članarinu',
                  type: 'text',
                  name: 'i_membership_regnum'
                },
                {
                  label: 'Međunarodnu članarinu plaća privatno?',
                  type: 'toggle',
                  name: 'i_membership_private'
                }
              ]
            }
          ]
        },
        {
          title: 'ODBORI',
          rows: [
            {
              id: 14,
              fields: [
                {
                  label: 'Odbori kojima pripada osoba',
                  type: 'board',
                  name: 'board_membership'
                }
              ]
            }
          ]
        }
      ]"
    ></BaseForm>
  </v-scale-transition>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";

export default {
  data() {
    return {};
  },
  components: {
    BaseForm
  }
};
</script>
